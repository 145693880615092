<template>
  <section class="set-password">
    <!-- 标题 -->
    <h1 class="title">{{ langSetPws.langSetPws_title }}</h1>
    <p class="tips">{{ langSetPws.langSetPws_desc }}</p>
    <!-- 密码 -->
    <input-password :inpObj="password" :placeholder="langSetPws.pwsPlh" />
    <!-- <input-password
      class="password-again"
      :inpObj="passwordAgain"
      :placeholder="langSetPws.pwsPlh"
    /> -->
    <!-- 按钮 -->
    <el-button
      v-dbClick
      @click="completeFn"
      class="el-btn"
      type="primary"
      :class="{ 'el-disabled': inpValLenFlag }"
      round
    >
      {{ langSetPws.langSetPws_btnText }}
    </el-button>
  </section>
</template>

<script>
import { mapState } from "vuex";
// import { langSetPws } from "./language";
import InputPassword from "./components/input-password.vue";
import { alexaRegister, alexaReset } from "@/api/alexaLogin.js";

export default {
  data() {
    return {
      password: {
        inpValue: "",
        autoFocus: true,
      },
      passwordAgain: {
        inpValue: "",
        autoFocus: false,
      },
      portInforNor: {
        //默认值
        area: "美国",
        phoneCode: 1,
        countryCode: "US",
      },
    };
  },
  computed: {
    ...mapState("alexaLogin", [
      "tabIndex",
      "portList",
      "portIndex",
      "languagePackage",
    ]),
    langSetPws() {
      return this.languagePackage[this.tabIndex];
    },
    from() {
      return this.$route.query.from;
    },
    inpValLenFlag() {
      return this.password.inpValue.length < 6;
      // return this.password.inpValue.length === 0;
    },
    portInfor() {
      return this.portList.length > 0
        ? this.portList[this.portIndex]
        : this.portInforNor;
    },
  },
  components: { InputPassword },
  methods: {
    completeFn() {
      if (this.inpValLenFlag) return;
      const password = this.password.inpValue;
      // const passwordAgain = this.passwordAgain.inpValue;
      // if (password !== passwordAgain)
      //   // 校验2次密码输入一致
      //   return this.$toast(this.langSetPws.pwsToast);
      if (!this.$commonFunc.verifyPassword(password))
        // 校验密码输入格式
        return this.$toast(this.langSetPws.pwsErrToast);
      if (this.from === "register") {
        this._alexaRegister(password);
      } else if (this.from === "reset") {
        this._alexaReset(password);
      }
    },
    // 注册密码
    _alexaRegister(password) {
      console.log("this.portInfor--->", this.portInfor);
      const { verifyId, account } = this.$route.query;
      const { mobile, phoneCode, countryCode } = this.portInfor;
      let infor = {};
      if (this.portInfor.mobile) {
        infor.mobile = mobile;
        infor.phoneAreacode = phoneCode;
        infor.userFlag = 1;
      } else {
        infor.email = account;
        infor.userFlag = 0;
      }
      alexaRegister({
        password: this.$commonFunc.encryptPassword(password),
        verifyId: this.$commonFunc.encryptCode(verifyId),
        verifyIdReceiver: account,
        countryCode: countryCode,
        ...infor,
      }).then(() => {
        this.$router.replace(`/${this.$commonFunc.getUrlChannelAppID()}/login/${this.$commonFunc.linkUrlParams()}`);
      });
    },
    // 重置密码
    _alexaReset(password) {
      const { verifyId, account } = this.$route.query;
      alexaReset({
        password: this.$commonFunc.encryptPassword(password),
        verifyId: this.$commonFunc.encryptCode(verifyId),
        verifyIdReceiver: account,
      }).then(() => {
        this.$toast(this.langSetPws.resetSuccess);
        this.$router.replace(`/${this.$commonFunc.getUrlChannelAppID()}/login/${this.$commonFunc.linkUrlParams()}`);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.set-password {
  .title {
    padding-top: 40px;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.9);
  }
  .tips {
    margin: 12px 0 20px 0;
    color: rgba(0, 0, 0, 0.4);
  }
  font-size: 14px;
  padding: 0 30px;
  .password-again {
    margin-top: 17px;
  }
  .el-btn {
    width: 100%;
    margin-top: 80px;
    height: 44px;
    border: none;
    &.el-disabled {
      background-color: #a0cfff;
    }
  }
}
</style>
